export const selectTitle = {
  AFP: 'Indica el fondo de pensiones al que estás afiliado/a',
  ISAPRE: 'Indica la entidad de salud a la que estás afiliado/a',
  Bancos: 'Indícanos cuál es tu Banco',
  region: 'Indica la región en la que resides',
  localidad: 'Indica la localidad o comuna en la que resides',
  nacionalidad: 'Indica cual es tu nacionalidad'
}

export const selectOptions = {
  nacionalidad: [
    {
      label: 'Canadá',

      value: 'Canadá'
    },
    {
      label: 'Líbano',

      value: 'Líbano'
    },
    {
      label: 'Mali',

      value: 'Mali'
    },
    {
      label: 'Mauricio',

      value: 'Mauricio'
    },
    {
      label: 'Namibia',

      value: 'Namibia'
    },
    {
      label: 'Nigeria',

      value: 'Nigeria'
    },
    {
      label: 'Países Bajos',

      value: 'Países Bajos'
    },
    {
      label: 'Nauru',

      value: 'Nauru'
    },
    {
      label: 'Botsuana',

      value: 'Botsuana'
    },
    {
      label: 'Emiratos Árabes Unidos',

      value: 'Emiratos Árabes Unidos'
    },
    {
      label: 'Anguila',

      value: 'Anguila'
    },
    {
      label: 'Albania',

      value: 'Albania'
    },
    {
      label: 'Armenia',

      value: 'Armenia'
    },
    {
      label: 'Samoa Americana',

      value: 'Samoa Americana'
    },
    {
      label: 'Austria',

      value: 'Austria'
    },
    {
      label: 'Aruba',

      value: 'Aruba'
    },
    {
      label: 'Azerbaiyán',

      value: 'Azerbaiyán'
    },
    {
      label: 'Burkina Faso',

      value: 'Burkina Faso'
    },
    {
      label: 'Bulgaria',

      value: 'Bulgaria'
    },
    {
      label: 'Benín',

      value: 'Benín'
    },
    {
      label: 'Bermudas',

      value: 'Bermudas'
    },
    {
      label: 'Bolivia',

      value: 'Bolivia'
    },
    {
      label: 'Bahamas',

      value: 'Bahamas'
    },
    {
      label: 'Bután',

      value: 'Bután'
    },
    {
      label: 'Isla Bouvet',

      value: 'Isla Bouvet'
    },
    {
      label: 'República Democrática del Congo',

      value: 'República Democrática del Congo'
    },
    {
      label: 'Congo',

      value: 'Congo'
    },
    {
      label: 'Andorra',

      value: 'Andorra'
    },
    {
      label: 'Afganistán',

      value: 'Afganistán'
    },
    {
      label: 'Antigua y Barbuda',

      value: 'Antigua y Barbuda'
    },
    {
      label: 'Angola',

      value: 'Angola'
    },
    {
      label: 'Antártida',

      value: 'Antártida'
    },
    {
      label: 'Argentina',

      value: 'Argentina'
    },
    {
      label: 'Australia',

      value: 'Australia'
    },
    {
      label: 'Islas Åland',

      value: 'Islas Åland'
    },
    {
      label: 'Bosnia-Herzegovina',

      value: 'Bosnia-Herzegovina'
    },
    {
      label: 'Barbados',

      value: 'Barbados'
    },
    {
      label: 'Bangladés',

      value: 'Bangladés'
    },
    {
      label: 'Bélgica',

      value: 'Bélgica'
    },
    {
      label: 'Bahréin',

      value: 'Bahréin'
    },
    {
      label: 'Burundi',

      value: 'Burundi'
    },
    {
      label: 'San Bartolomé',

      value: 'San Bartolomé'
    },
    {
      label: 'Brunéi Darussalam',

      value: 'Brunéi Darussalam'
    },
    {
      label: 'Bonaire San Eustaquio y Saba',

      value: 'Bonaire San Eustaquio y Saba'
    },
    {
      label: 'Brasil',

      value: 'Brasil'
    },
    {
      label: 'Bielorrusia',

      value: 'Bielorrusia'
    },
    {
      label: 'Belice',

      value: 'Belice'
    },
    {
      label: 'Islas Cocos o Keeling',

      value: 'Islas Cocos o Keeling'
    },
    {
      label: 'República Centroafricana',

      value: 'República Centroafricana'
    },
    {
      label: 'Suiza',

      value: 'Suiza'
    },
    {
      label: 'Cuba',

      value: 'Cuba'
    },
    {
      label: 'Chipre',

      value: 'Chipre'
    },
    {
      label: 'República Checa',

      value: 'República Checa'
    },
    {
      label: 'República Dominicana',

      value: 'República Dominicana'
    },
    {
      label: 'Argelia',

      value: 'Argelia'
    },
    {
      label: 'Ecuador',

      value: 'Ecuador'
    },
    {
      label: 'España',

      value: 'España'
    },
    {
      label: 'Etiopía',

      value: 'Etiopía'
    },
    {
      label: 'Finlandia',

      value: 'Finlandia'
    },
    {
      label: 'Fiyi',

      value: 'Fiyi'
    },
    {
      label: 'Francia',

      value: 'Francia'
    },
    {
      label: 'Granada',

      value: 'Granada'
    },
    {
      label: 'Ghana',

      value: 'Ghana'
    },
    {
      label: 'Groenlandia',

      value: 'Groenlandia'
    },
    {
      label: 'Gambia',

      value: 'Gambia'
    },
    {
      label: 'Guinea',

      value: 'Guinea'
    },
    {
      label: 'Guinea Ecuatorial',

      value: 'Guinea Ecuatorial'
    },
    {
      label: 'Grecia',

      value: 'Grecia'
    },
    {
      label: 'Guyana',

      value: 'Guyana'
    },
    {
      label: 'Hong Kong',

      value: 'Hong Kong'
    },
    {
      label: 'Honduras',

      value: 'Honduras'
    },
    {
      label: 'Croacia',

      value: 'Croacia'
    },
    {
      label: 'Irlanda',

      value: 'Irlanda'
    },
    {
      label: 'India',

      value: 'India'
    },
    {
      label: 'Territorio Británico del Océano Índico',

      value: 'Territorio Británico del Océano Índico'
    },
    {
      label: 'Irak',

      value: 'Irak'
    },
    {
      label: 'Islandia',

      value: 'Islandia'
    },
    {
      label: 'Jersey',

      value: 'Jersey'
    },
    {
      label: 'Jamaica',

      value: 'Jamaica'
    },
    {
      label: 'Jordania',

      value: 'Jordania'
    },
    {
      label: 'Japón',

      value: 'Japón'
    },
    {
      label: 'Kenia',

      value: 'Kenia'
    },
    {
      label: 'Camboya',

      value: 'Camboya'
    },
    {
      label: 'Kiribati',

      value: 'Kiribati'
    },
    {
      label: 'Comoras',

      value: 'Comoras'
    },
    {
      label: 'República de Corea',

      value: 'República de Corea'
    },
    {
      label: 'Kuwait',

      value: 'Kuwait'
    },
    {
      label: 'Kazajistán',

      value: 'Kazajistán'
    },
    {
      label: 'Laos',

      value: 'Laos'
    },
    {
      label: 'Santa Lucía',

      value: 'Santa Lucía'
    },
    {
      label: 'Sri Lanka',

      value: 'Sri Lanka'
    },
    {
      label: 'Liberia',

      value: 'Liberia'
    },
    {
      label: 'Marruecos',

      value: 'Marruecos'
    },
    {
      label: 'Macedonia',

      value: 'Macedonia'
    },
    {
      label: 'Birmania',

      value: 'Birmania'
    },
    {
      label: 'Montserrat',

      value: 'Montserrat'
    },
    {
      label: 'Nepal',

      value: 'Nepal'
    },
    {
      label: 'Niue',

      value: 'Niue'
    },
    {
      label: 'Perú',

      value: 'Perú'
    },
    {
      label: 'Papúa Nueva Guinea',

      value: 'Papúa Nueva Guinea'
    },
    {
      label: 'Filipinas',

      value: 'Filipinas'
    },
    {
      label: 'Pakistán',

      value: 'Pakistán'
    },
    {
      label: 'Portugal',

      value: 'Portugal'
    },
    {
      label: 'Qatar',

      value: 'Qatar'
    },
    {
      label: 'Rumania',

      value: 'Rumania'
    },
    {
      label: 'Arabia Saudita',

      value: 'Arabia Saudita'
    },
    {
      label: 'Islas Salomón',

      value: 'Islas Salomón'
    },
    {
      label: 'Seychelles',

      value: 'Seychelles'
    },
    {
      label: 'Sudán',

      value: 'Sudán'
    },
    {
      label: 'Suecia',

      value: 'Suecia'
    },
    {
      label: 'Sierra Leona',

      value: 'Sierra Leona'
    },
    {
      label: 'San Marino',

      value: 'San Marino'
    },
    {
      label: 'Senegal',

      value: 'Senegal'
    },
    {
      label: 'Somalía',

      value: 'Somalía'
    },
    {
      label: 'Costa de Marfil',

      value: 'Costa de Marfil'
    },
    {
      label: 'Islas Cook',

      value: 'Islas Cook'
    },
    {
      label: 'Chile',

      value: 'Chile'
    },
    {
      label: 'Camerún',

      value: 'Camerún'
    },
    {
      label: 'China',

      value: 'China'
    },
    {
      label: 'Colombia',

      value: 'Colombia'
    },
    {
      label: 'Costa Rica',

      value: 'Costa Rica'
    },
    {
      label: 'Cabo Verde',

      value: 'Cabo Verde'
    },
    {
      label: 'Curazao',

      value: 'Curazao'
    },
    {
      label: 'Isla Christmas',

      value: 'Isla Christmas'
    },
    {
      label: 'Alemania',

      value: 'Alemania'
    },
    {
      label: 'Yibuti',

      value: 'Yibuti'
    },
    {
      label: 'Dinamarca',

      value: 'Dinamarca'
    },
    {
      label: 'Dominica',

      value: 'Dominica'
    },
    {
      label: 'Estonia',

      value: 'Estonia'
    },
    {
      label: 'Egipto',

      value: 'Egipto'
    },
    {
      label: 'Sáhara Occidental',

      value: 'Sáhara Occidental'
    },
    {
      label: 'Eritrea',

      value: 'Eritrea'
    },
    {
      label: 'Islas Malvinas',

      value: 'Islas Malvinas'
    },
    {
      label: 'Micronesia',

      value: 'Micronesia'
    },
    {
      label: 'Islas Feroe',

      value: 'Islas Feroe'
    },
    {
      label: 'Gabón',

      value: 'Gabón'
    },
    {
      label: 'Reino Unido',

      value: 'Reino Unido'
    },
    {
      label: 'Georgia',

      value: 'Georgia'
    },
    {
      label: 'Guernesey',

      value: 'Guernesey'
    },
    {
      label: 'Gibraltar',

      value: 'Gibraltar'
    },
    {
      label: 'Islas Georgia y Sandwich',

      value: 'Islas Georgia y Sandwich'
    },
    {
      label: 'Guatemala',

      value: 'Guatemala'
    },
    {
      label: 'Guinea-Bisáu',

      value: 'Guinea-Bisáu'
    },
    {
      label: 'Islas Heard y McDonald',

      value: 'Islas Heard y McDonald'
    },
    {
      label: 'Haití',

      value: 'Haití'
    },
    {
      label: 'Hungría',

      value: 'Hungría'
    },
    {
      label: 'Indonesia',

      value: 'Indonesia'
    },
    {
      label: 'Israel',

      value: 'Israel'
    },
    {
      label: 'Isla de Man',

      value: 'Isla de Man'
    },
    {
      label: 'Irán',

      value: 'Irán'
    },
    {
      label: 'Italia',

      value: 'Italia'
    },
    {
      label: 'Kirguistán',

      value: 'Kirguistán'
    },
    {
      label: 'San Cristóbal y Nieves',

      value: 'San Cristóbal y Nieves'
    },
    {
      label: 'República popular democrática de Corea',

      value: 'República popular democrática de Corea'
    },
    {
      label: 'Islas Caimán',

      value: 'Islas Caimán'
    },
    {
      label: 'Liechtenstein',

      value: 'Liechtenstein'
    },
    {
      label: 'Lesoto',

      value: 'Lesoto'
    },
    {
      label: 'Lituania',

      value: 'Lituania'
    },
    {
      label: 'Luxemburgo',

      value: 'Luxemburgo'
    },
    {
      label: 'Letonia',

      value: 'Letonia'
    },
    {
      label: 'Libia',

      value: 'Libia'
    },
    {
      label: 'Mónaco',

      value: 'Mónaco'
    },
    {
      label: 'República de Moldova',

      value: 'República de Moldova'
    },
    {
      label: 'Montenegro',

      value: 'Montenegro'
    },
    {
      label: 'Madagascar',

      value: 'Madagascar'
    },
    {
      label: 'Islas Marshall',

      value: 'Islas Marshall'
    },
    {
      label: 'Mongolia',

      value: 'Mongolia'
    },
    {
      label: 'Mauritania',

      value: 'Mauritania'
    },
    {
      label: 'Malta',

      value: 'Malta'
    },
    {
      label: 'Maldivas',

      value: 'Maldivas'
    },
    {
      label: 'Malaui',

      value: 'Malaui'
    },
    {
      label: 'México',

      value: 'México'
    },
    {
      label: 'Malasia',

      value: 'Malasia'
    },
    {
      label: 'Mozambique',

      value: 'Mozambique'
    },
    {
      label: 'Níger',

      value: 'Níger'
    },
    {
      label: 'Isla Norfolk',

      value: 'Isla Norfolk'
    },
    {
      label: 'Nicaragua',

      value: 'Nicaragua'
    },
    {
      label: 'Noruega',

      value: 'Noruega'
    },
    {
      label: 'Nueva Zelanda',

      value: 'Nueva Zelanda'
    },
    {
      label: 'Omán',

      value: 'Omán'
    },
    {
      label: 'Panamá',

      value: 'Panamá'
    },
    {
      label: 'Polonia',

      value: 'Polonia'
    },
    {
      label: 'Islas Pitcairn',

      value: 'Islas Pitcairn'
    },
    {
      label: 'Territorios Palestinos',

      value: 'Territorios Palestinos'
    },
    {
      label: 'Palaos',

      value: 'Palaos'
    },
    {
      label: 'Paraguay',

      value: 'Paraguay'
    },
    {
      label: 'Serbia',

      value: 'Serbia'
    },
    {
      label: 'Rusia',

      value: 'Rusia'
    },
    {
      label: 'Ruanda',

      value: 'Ruanda'
    },
    {
      label: 'Singapur',

      value: 'Singapur'
    },
    {
      label: 'El Salvador',

      value: 'El Salvador'
    },
    {
      label: 'Sint Maarten',

      value: 'Sint Maarten'
    },
    {
      label: 'Chad',

      value: 'Chad'
    },
    {
      label: 'Timor Oriental',

      value: 'Timor Oriental'
    },
    {
      label: 'Trinidad y Tobago',

      value: 'Trinidad y Tobago'
    },
    {
      label: 'Tuvalu',

      value: 'Tuvalu'
    },
    {
      label: 'Taiwán',

      value: 'Taiwán'
    },
    {
      label: 'Tanzania',

      value: 'Tanzania'
    },
    {
      label: 'Ucrania',

      value: 'Ucrania'
    },
    {
      label: 'Uganda',

      value: 'Uganda'
    },
    {
      label: 'Uzbekistán',

      value: 'Uzbekistán'
    },
    {
      label: 'Ciudad del Vaticano',

      value: 'Ciudad del Vaticano'
    },
    {
      label: 'San Vicente y las Granadinas',

      value: 'San Vicente y las Granadinas'
    },
    {
      label: 'Venezuela',

      value: 'Venezuela'
    },
    {
      label: 'Islas Vírgenes Británicas',

      value: 'Islas Vírgenes Británicas'
    },
    {
      label: 'Vanuatu',

      value: 'Vanuatu'
    },
    {
      label: 'Samoa',

      value: 'Samoa'
    },
    {
      label: 'Zambia', value: 'Zambia'},
    {
      label: 'Surinam',

      value: 'Surinam'
    },
    {
      label: 'Sudán del Sur',

      value: 'Sudán del Sur'
    },
    {
      label: 'Santo Tomé y Príncipe',

      value: 'Santo Tomé y Príncipe'
    },
    {
      label: 'Siria',

      value: 'Siria'
    },
    {
      label: 'Suazilandia',

      value: 'Suazilandia'
    },
    {
      label: 'Islas Turcos y Caicos',

      value: 'Islas Turcos y Caicos'
    },
    {
      label: 'Togo',

      value: 'Togo'
    },
    {
      label: 'Tailandia',

      value: 'Tailandia'
    },
    {
      label: 'Tayikistán',

      value: 'Tayikistán'
    },
    {
      label: 'Tokelau',

      value: 'Tokelau'
    },
    {
      label: 'Turkmenistán',

      value: 'Turkmenistán'
    },
    {
      label: 'Túnez', value: 'Túnez'},
    {
      label: 'Tonga',

      value: 'Tonga'
    },
    {
      label: 'Turquía',

      value: 'Turquía'
    },
    {
      label: 'Estados Unidos',

      value: 'Estados Unidos'
    },
    {
      label: 'Uruguay',

      value: 'Uruguay'
    },
    {
      label: 'Vietnam',

      value: 'Vietnam'
    },
    {
      label: 'Yemen',

      value: 'Yemen'
    },
    {
      label: 'Sudáfrica',

      value: 'Sudáfrica'
    },
    {
      label: 'Zimbabue',

      value: 'Zimbabue'
    },
    {
      label: 'Isla Santa Elena',

      value: 'Isla Santa Elena'
    },
    {
      label: 'Eslovenia',

      value: 'Eslovenia'
    },
    {
      label: 'Eslovaquia',

      value: 'Eslovaquia'
    }
  ],
  AFP: [
    {
      value: "PROVIDA",

      label: "PROVIDA",
    },

    {
      value: "HABITAT",

      label: "HABITAT",
    },

    {
      value: "EXTRANJEROS",

      label: "EXTRANJEROS",
    },

    {
      value: "CUPRUM",

      label: "CUPRUM",
    },

    {
      value: "CAPITAL",

      label: "CAPITAL",
    },

    {
      value: "JUBILADOS",

      label: "JUBILADOS",
    },

    {
      value: "HABITAT 2",

      label: "HABITAT 2",
    },

    {
      value: "MODELO",

      label: "MODELO",
    },

    {
      value: "EMPART",

      label: "EMPART",
    },

    {
      value: "PLANVITAL",

      label: "PLANVITAL",
    },
    {
      value: "S.S.S.",

      label: "S.S.S.",
    },
    {
      value: "PROVIDA 2",

      label: "PROVIDA 2",
    },
    {
      value: "CANAEMPU",

      label: "CANAEMPU",
    },
    {
      value: "CAPITAL 2",

      label: "CAPITAL 2",
    },
    {
      value: "Instituto de Prevision Social",

      label: "Instituto de Prevision Social",
    },
    {
      value: "UNO",

      label: "UNO",
    },
    {
      value: "NULA",

      label: "NULA",
    },
  ],
  ISAPRE: [
    {
      value: "CONSALUD",

      label: "CONSALUD",
    },

    {
      value: "FONASA",

      label: "FONASA",
    },

    {
      value: "CHUQUICAMATA",

      label: "CHUQUICAMATA",
    },

    {
      value: "CRUZBLANCA",

      label: "CRUZBLANCA",
    },

    {
      value: "FUSAT",

      label: "FUSAT",
    },

    {
      value: "COLMENA GOLDEN CROSS",

      label: "COLMENA GOLDEN CROSS",
    },

    {
      value: "BANMEDICA",

      label: "BANMEDICA",
    },

    {
      value: "Isapre San Lorenzo",

      label: "Isapre San Lorenzo",
    },

    {
      value: "VIDA TRES",

      label: "VIDA TRES",
    },

    {
      value: "NUEVA MAS VIDA",

      label: "NUEVA MAS VIDA",
    },
    {
      value: "FUNDACION",

      label: "FUNDACION",
    },
    {
      value: "FERROSALUD",

      label: "FERROSALUD",
    },
    {
      value: "NULO",

      label: "NULO",
    },
  ],
  Bancos: [
    {
      value: "Sin banco",

      label: "Sin banco",
    },

    {
      value: "BANCO DE CHILE",

      label: "BANCO DE CHILE",
    },

    {
      value: "BANCO BBVA",

      label: "BANCO BBVA",
    },

    {
      value: "BANCO ABN AMRO",

      label: "BANCO ABN AMRO",
    },

    {
      value: "BANCO CORPBANCA",

      label: "BANCO CORPBANCA",
    },

    {
      value: "BANCO SANTIAGO",

      label: "BANCO SANTIAGO",
    },

    {
      value: "BANCO DEL DESARROLLO",

      label: "BANCO DEL DESARROLLO",
    },

    {
      value: "BANCO BCI",

      label: "BANCO BCI",
    },

    {
      value: "BANCOESTADO",

      label: "BANCOESTADO",
    },

    {
      value: "BANCO DE A. EDWARDS",

      label: "BANCO DE A. EDWARDS",
    },
    {
      value: "BANCO ITAU",

      label: "BANCO ITAU",
    },
    {
      value: "CITIBANK",

      label: "CITIBANK",
    },
    {
      value: "BANCO SUDAMERICANO",

      label: "BANCO SUDAMERICANO",
    },
    {
      value: "BANCO SANTANDER",

      label: "BANCO SANTANDER",
    },
    {
      value: "BANEFE",

      label: "BANEFE",
    },
    {
      value: "BANCO FALABELLA",

      label: "BANCO FALABELLA",
    },
    {
      value: "BANCO SECURITY PACIFIC",

      label: "BANCO SECURITY PACIFIC",
    },
    {
      value: "BANCO SCOTIABANK",

      label: "BANCO SCOTIABANK",
    },
    {
      value: "BANCO BICE",

      label: "BANCO BICE",
    },
    {
      value: "Cheque Ret.Judicial",

      label: "Cheque Ret.Judicial",
    },
    {
      value: "BANCO CONSORCIO",

      label: "BANCO CONSORCIO",
    },
  ],
  region: [
    {
      "label": "Región Aisén del Gral. Carlos Ibáñez del Campo",
      "value": "Región Aisén del Gral. Carlos Ibáñez del Campo"
    },
    {
      "label": "Antofagasta",
      "value": "Antofagasta"
    },
    {
      "label": "Región de La Araucanía",
      "value": "Región de La Araucanía"
    },
    {
      "label": "Atacama",
      "value": "Atacama"
    },
    {
      "label": "Región del Biobío",
      "value": "Región del Biobío"
    },
    {
      "label": "Coquimbo",
      "value": "Coquimbo"
    },
    {
      "label": "Región de Los Lagos",
      "value": "Región de Los Lagos"
    },
    {
      "label": "Región del Libertador Gral. Bernardo",
      "value": "Región del Libertador Gral. Bernardo"
    },
    {
      "label": "Región de Magallanes y de la Antártica Chilena",
      "value": "Región de Magallanes y de la Antártica Chilena"
    },
    {
      "label": "Región del Maule",
      "value": "Región del Maule"
    },
    {
      "label": "Región Metropolitana de Santiago",
      "value": "Región Metropolitana de Santiago"
    },
    {
      "label": "Tarapacá",
      "value": "Tarapacá"
    },
    {
      "label": "Valparaíso",
      "value": "Valparaíso"
    },
    {
      "label": "Parinacota",
      "value": "Parinacota"
    },
    {
      "label": "Región de Los Ríos",
      "value": "Región de Los Ríos"
    }
  ],
  localidad: {
    "1041": [
      {
        "label": "CHILE-Chile",
        "value": "CL-16-1-1"
      }
    ],
    "Región Aisén del Gral. Carlos Ibáñez del Campo": [
      {
        "label": "CHACABUCO (COYHAIQUE)-COYHAIQUE",
        "value": "CL-11-11-2"
      },
      {
        "label": "BALMACEDA-COYHAIQUE",
        "value": "CL-11-11-1"
      },
      {
        "label": "CHILE CHICO-COYHAIQUE",
        "value": "CL-11-11-3"
      },
      {
        "label": "COCHRANE-COYHAIQUE",
        "value": "CL-11-11-4"
      },
      {
        "label": "COYHAIQUE-COYHAIQUE",
        "value": "CL-11-11-5"
      },
      {
        "label": "LA JUNTA-COYHAIQUE",
        "value": "CL-11-11-6"
      },
      {
        "label": "PUERTO AYSEN-COYHAIQUE",
        "value": "CL-11-11-7"
      },
      {
        "label": "PUERTO CHACABUCO-COYHAIQUE",
        "value": "CL-11-11-8"
      },
      {
        "label": "PUERTO CISNES-COYHAIQUE",
        "value": "CL-11-11-9"
      },
      {
        "label": "PUYUHAUPI-COYHAIQUE",
        "value": "CL-11-11-10"
      },
      {
        "label": "VILLA OHIGGINS-COYHAIQUE",
        "value": "CL-11-11-11"
      }
    ],
    "Antofagasta": [
      {
        "label": "ANTOFAGASTA-ANTOFAGASTA",
        "value": "CL-02-2-1"
      },
      {
        "label": "BAQUEDANO-ANTOFAGASTA",
        "value": "CL-02-2-6"
      },
      {
        "label": "CALAMA-ANTOFAGASTA",
        "value": "CL-02-2-2"
      },
      {
        "label": "CALAMA-CALAMA",
        "value": "CL-02-52-1"
      },
      {
        "label": "CHUQUICAMATA-ANTOFAGASTA",
        "value": "CL-02-2-4"
      },
      {
        "label": "CHUQUICAMATA-CALAMA",
        "value": "CL-02-52-3"
      },
      {
        "label": "EL LOA-CALAMA",
        "value": "CL-02-52-2"
      },
      {
        "label": "MARIA ELENA-ANTOFAGASTA",
        "value": "CL-02-2-5"
      },
      {
        "label": "MEJILLONES-ANTOFAGASTA",
        "value": "CL-02-2-7"
      },
      {
        "label": "OLLAGUE-CALAMA",
        "value": "CL-02-52-4"
      },
      {
        "label": "RADOMIRO-CALAMA",
        "value": "CL-02-52-5"
      },
      {
        "label": "SAN PEDRO DE ATACAMA-CALAMA",
        "value": "CL-02-52-6"
      },
      {
        "label": "SIERRA GORDA-ANTOFAGASTA",
        "value": "CL-02-2-8"
      },
      {
        "label": "TALTAL-ANTOFAGASTA",
        "value": "CL-02-2-9"
      },
      {
        "label": "TOCOPILLA-ANTOFAGASTA",
        "value": "CL-02-2-3"
      }
    ],
    "Región de La Araucanía": [
      {
        "label": "ANGOL-TEMUCO",
        "value": "CL-09-9-5"
      },
      {
        "label": "CARAHUE-TEMUCO",
        "value": "CL-09-9-22"
      },
      {
        "label": "CAUTIN-TEMUCO",
        "value": "CL-09-9-14"
      },
      {
        "label": "CHOL-CHOL-TEMUCO",
        "value": "CL-09-9-38"
      },
      {
        "label": "CLARO SOLAR TEMUCO-TEMUCO",
        "value": "CL-09-9-39"
      },
      {
        "label": "CULLIPULLI-TEMUCO",
        "value": "CL-09-9-8"
      },
      {
        "label": "CUNCO-TEMUCO",
        "value": "CL-09-9-17"
      },
      {
        "label": "CURACAUTIN-TEMUCO",
        "value": "CL-09-9-7"
      },
      {
        "label": "CURARREHUE-TEMUCO",
        "value": "CL-09-9-24"
      },
      {
        "label": "ERCILLA-TEMUCO",
        "value": "CL-09-9-23"
      },
      {
        "label": "FREIRE-TEMUCO",
        "value": "CL-09-9-18"
      },
      {
        "label": "GALVARINO-TEMUCO",
        "value": "CL-09-9-25"
      },
      {
        "label": "GORBEA-TEMUCO",
        "value": "CL-09-9-21"
      },
      {
        "label": "LANCO-TEMUCO",
        "value": "CL-09-9-10"
      },
      {
        "label": "LAUTARO-TEMUCO",
        "value": "CL-09-9-12"
      },
      {
        "label": "LICANRAY-TEMUCO",
        "value": "CL-09-9-26"
      },
      {
        "label": "LONCOCHE-TEMUCO",
        "value": "CL-09-9-6"
      },
      {
        "label": "LONQUIMAY-TEMUCO",
        "value": "CL-09-9-27"
      },
      {
        "label": "LOS SAUCES-TEMUCO",
        "value": "CL-09-9-40"
      },
      {
        "label": "MALLECO-TEMUCO",
        "value": "CL-09-9-28"
      },
      {
        "label": "MELIPEUCO-TEMUCO",
        "value": "CL-09-9-29"
      },
      {
        "label": "MELIPUECO-TEMUCO",
        "value": "CL-09-9-19"
      },
      {
        "label": "NUEVA IMPERIAL-TEMUCO",
        "value": "CL-09-9-30"
      },
      {
        "label": "PADRE LAS CASAS-TEMUCO",
        "value": "CL-09-9-9"
      },
      {
        "label": "PAILLACO-TEMUCO",
        "value": "CL-09-9-11"
      },
      {
        "label": "PERQUENCO-TEMUCO",
        "value": "CL-09-9-31"
      },
      {
        "label": "PICHIPELLAHUEN-TEMUCO",
        "value": "CL-09-9-32"
      },
      {
        "label": "PITRUFQUEN-TEMUCO",
        "value": "CL-09-9-13"
      },
      {
        "label": "PUCON-TEMUCO",
        "value": "CL-09-9-16"
      },
      {
        "label": "PUERTO SAAVEDRA-TEMUCO",
        "value": "CL-09-9-33"
      },
      {
        "label": "QUEPE-TEMUCO",
        "value": "CL-09-9-34"
      },
      {
        "label": "RENAICO-TEMUCO",
        "value": "CL-09-9-20"
      },
      {
        "label": "TEMUCO-TEMUCO",
        "value": "CL-09-9-1"
      },
      {
        "label": "TEODORO SCHMIDT-TEMUCO",
        "value": "CL-09-9-35"
      },
      {
        "label": "TOLTEN-TEMUCO",
        "value": "CL-09-9-36"
      },
      {
        "label": "TORREMOLINOS-TEMUCO",
        "value": "CL-09-9-41"
      },
      {
        "label": "TRAIGUEN-TEMUCO",
        "value": "CL-09-9-15"
      },
      {
        "label": "TROVOLHUE-TEMUCO",
        "value": "CL-09-9-37"
      },
      {
        "label": "VICTORIA-TEMUCO",
        "value": "CL-09-9-4"
      },
      {
        "label": "VILCUN-TEMUCO",
        "value": "CL-09-9-3"
      },
      {
        "label": "VILLARRICA-TEMUCO",
        "value": "CL-09-9-2"
      }
    ],
    "Atacama": [
      {
        "label": "ALTO DEL CARMEN-COPIAPO",
        "value": "CL-03-3-8"
      },
      {
        "label": "BAHIA INGLESA-COPIAPO",
        "value": "CL-03-3-9"
      },
      {
        "label": "CALDERA-COPIAPO",
        "value": "CL-03-3-4"
      },
      {
        "label": "CHANARCILLO-COPIAPO",
        "value": "CL-03-3-10"
      },
      {
        "label": "CHAÑARAL-COPIAPO",
        "value": "CL-03-3-3"
      },
      {
        "label": "COLIPI-COPIAPO",
        "value": "CL-03-3-11"
      },
      {
        "label": "COPIAPO-COPIAPO",
        "value": "CL-03-3-1"
      },
      {
        "label": "DIEGO DE ALMAGRO-COPIAPO",
        "value": "CL-03-3-12"
      },
      {
        "label": "DOMEYKO-COPIAPO",
        "value": "CL-03-3-13"
      },
      {
        "label": "EL SALADO-COPIAPO",
        "value": "CL-03-3-14"
      },
      {
        "label": "EL SALVADOR-COPIAPO",
        "value": "CL-03-3-15"
      },
      {
        "label": "FREIRINA-COPIAPO",
        "value": "CL-03-3-20"
      },
      {
        "label": "HUASCO-COPIAPO",
        "value": "CL-03-3-16"
      },
      {
        "label": "MINERA CANDELARIA-COPIAPO",
        "value": "CL-03-3-17"
      },
      {
        "label": "PAIPOTE-COPIAPO",
        "value": "CL-03-3-6"
      },
      {
        "label": "POTRERILLOS-COPIAPO",
        "value": "CL-03-3-18"
      },
      {
        "label": "PUERTO HUASCO-COPIAPO",
        "value": "CL-03-3-19"
      },
      {
        "label": "TIERRA AMARILLA-COPIAPO",
        "value": "CL-03-3-2"
      },
      {
        "label": "VALLENAR-COPIAPO",
        "value": "CL-03-3-5"
      }
    ],
    "Región del Biobío": [
      {
        "label": "CHACABUCO ( CONCEPCION )-CONCEPCION",
        "value": "CL-08-8-34"
      },
      {
        "label": "ANGOL-LOS ANGELES",
        "value": "CL-08-54-2"
      },
      {
        "label": "ANTUCO-LOS ANGELES",
        "value": "CL-08-54-9"
      },
      {
        "label": "ARAUCO-CONCEPCION",
        "value": "CL-08-8-21"
      },
      {
        "label": "BULNES-CHILLAN",
        "value": "CL-08-22-2"
      },
      {
        "label": "BULNES-CONCEPCION",
        "value": "CL-08-8-9"
      },
      {
        "label": "CABRERO-CONCEPCION",
        "value": "CL-08-8-30"
      },
      {
        "label": "CAÑETE-CONCEPCION",
        "value": "CL-08-8-24"
      },
      {
        "label": "CARAMPANGUE-CONCEPCION",
        "value": "CL-08-8-20"
      },
      {
        "label": "CAÝETE-CHILLAN",
        "value": "CL-08-22-7"
      },
      {
        "label": "CHIGUAYANTE-CONCEPCION",
        "value": "CL-08-8-4"
      },
      {
        "label": "CHILLAN VIEJO-CHILLAN",
        "value": "CL-08-22-4"
      },
      {
        "label": "CHILLAN-CHILLAN",
        "value": "CL-08-22-1"
      },
      {
        "label": "CHILLAN-CONCEPCION",
        "value": "CL-08-8-12"
      },
      {
        "label": "CHOLGUAN-CHILLAN",
        "value": "CL-08-22-8"
      },
      {
        "label": "COBQUECURA-CHILLAN",
        "value": "CL-08-22-9"
      },
      {
        "label": "COELEMU-CHILLAN",
        "value": "CL-08-22-10"
      },
      {
        "label": "COIHUECO-CHILLAN",
        "value": "CL-08-22-5"
      },
      {
        "label": "COIHUECO-CONCEPCION",
        "value": "CL-08-8-26"
      },
      {
        "label": "COLCURA-CONCEPCION",
        "value": "CL-08-8-35"
      },
      {
        "label": "COLLIPULLI-LOS ANGELES",
        "value": "CL-08-54-10"
      },
      {
        "label": "CONCEPCION-CONCEPCION",
        "value": "CL-08-8-1"
      },
      {
        "label": "CONTULMO-CONCEPCION",
        "value": "CL-08-8-36"
      },
      {
        "label": "CORONEL-CONCEPCION",
        "value": "CL-08-8-2"
      },
      {
        "label": "CURANILAHUE-CONCEPCION",
        "value": "CL-08-8-18"
      },
      {
        "label": "DICHATO-CONCEPCION",
        "value": "CL-08-8-37"
      },
      {
        "label": "EL CARMEN-CHILLAN",
        "value": "CL-08-22-11"
      },
      {
        "label": "FLORIDA-CONCEPCION",
        "value": "CL-08-8-38"
      },
      {
        "label": "HIGUERAS-CONCEPCION",
        "value": "CL-08-8-16"
      },
      {
        "label": "HUALPENCILLO-CONCEPCION",
        "value": "CL-08-8-25"
      },
      {
        "label": "HUALPEN-CONCEPCION",
        "value": "CL-08-8-39"
      },
      {
        "label": "HUALQUI-CONCEPCION",
        "value": "CL-08-8-14"
      },
      {
        "label": "HUEPIL-LOS ANGELES",
        "value": "CL-08-54-11"
      },
      {
        "label": "LAJA-CONCEPCION",
        "value": "CL-08-8-29"
      },
      {
        "label": "LAJA-LOS ANGELES",
        "value": "CL-08-54-6"
      },
      {
        "label": "LARAQUETE-CONCEPCION",
        "value": "CL-08-8-40"
      },
      {
        "label": "LAS HIGUERAS-CONCEPCION",
        "value": "CL-08-8-41"
      },
      {
        "label": "LAS SALINAS-CONCEPCION",
        "value": "CL-08-8-19"
      },
      {
        "label": "LEBU-CONCEPCION",
        "value": "CL-08-8-17"
      },
      {
        "label": "LIRQUEN-CONCEPCION",
        "value": "CL-08-8-42"
      },
      {
        "label": "LONCO-CONCEPCION",
        "value": "CL-08-8-43"
      },
      {
        "label": "LOS ALAMOS-CONCEPCION",
        "value": "CL-08-8-44"
      },
      {
        "label": "LOS ANGELES-BIOBíO",
        "value": "CL-08-61-10"
      },
      {
        "label": "LOS ANGELES-CONCEPCION",
        "value": "CL-08-8-8"
      },
      {
        "label": "LOS ANGELES-LOS ANGELES",
        "value": "CL-08-54-1"
      },
      {
        "label": "LOS ANNGELES-LOS ANGELES",
        "value": "CL-08-54-02"
      },
      {
        "label": "LOTA-CONCEPCION",
        "value": "CL-08-8-11"
      },
      {
        "label": "LUMACO-LOS ANGELES",
        "value": "CL-08-54-12"
      },
      {
        "label": "MONTE AGUILA-LOS ANGELES",
        "value": "CL-08-54-13"
      },
      {
        "label": "MULCHEN-CONCEPCION",
        "value": "CL-08-8-28"
      },
      {
        "label": "MULCHEN-LOS ANGELES",
        "value": "CL-08-54-5"
      },
      {
        "label": "NACIMIENTO-CONCEPCION",
        "value": "CL-08-8-31"
      },
      {
        "label": "NACIMIENTO-LOS ANGELES",
        "value": "CL-08-54-7"
      },
      {
        "label": "NEGRETE-CONCEPCION",
        "value": "CL-08-8-32"
      },
      {
        "label": "NEGRETE-LOS ANGELES",
        "value": "CL-08-54-8"
      },
      {
        "label": "NIPAS-CHILLAN",
        "value": "CL-08-22-12"
      },
      {
        "label": "NIQUEN-CHILLAN",
        "value": "CL-08-22-13"
      },
      {
        "label": "ÑUBLE-CONCEPCION",
        "value": "CL-08-8-27"
      },
      {
        "label": "PAICAVI-CONCEPCION",
        "value": "CL-08-8-45"
      },
      {
        "label": "PARRAL-CHILLAN",
        "value": "CL-08-22-27"
      },
      {
        "label": "PARRAL-CONCEPCION",
        "value": "CL-08-8-10"
      },
      {
        "label": "PEMUCO-CONCEPCION",
        "value": "CL-08-8-33"
      },
      {
        "label": "PENCO-CONCEPCION",
        "value": "CL-08-8-3"
      },
      {
        "label": "PENCO-CONCEPCION",
        "value": "CL-08-8-46"
      },
      {
        "label": "PINTO-CHILLAN",
        "value": "CL-08-22-14"
      },
      {
        "label": "PORTEZUELO-CHILLAN",
        "value": "CL-08-22-15"
      },
      {
        "label": "PUREN-LOS ANGELES",
        "value": "CL-08-54-14"
      },
      {
        "label": "QUILACO-LOS ANGELES",
        "value": "CL-08-54-15"
      },
      {
        "label": "QUILLECO-LOS ANGELES",
        "value": "CL-08-54-16"
      },
      {
        "label": "QUILLON-CHILLAN",
        "value": "CL-08-22-16"
      },
      {
        "label": "QUINCHAMALI-CHILLAN",
        "value": "CL-08-22-17"
      },
      {
        "label": "QUIRIHUE-CHILLAN",
        "value": "CL-08-22-18"
      },
      {
        "label": "RAFAEL-CONCEPCION",
        "value": "CL-08-8-47"
      },
      {
        "label": "RALCO-LOS ANGELES",
        "value": "CL-08-54-17"
      },
      {
        "label": "RANQUIL-CHILLAN",
        "value": "CL-08-22-19"
      },
      {
        "label": "RENAICO-LOS ANGELES",
        "value": "CL-08-54-3"
      },
      {
        "label": "RINCONADA DE LAJA-LOS ANGELES",
        "value": "CL-08-54-18"
      },
      {
        "label": "RIO CLARO - CONCEPCION-CONCEPCION",
        "value": "CL-08-8-48"
      },
      {
        "label": "SAN CARLOS-CHILLAN",
        "value": "CL-08-22-20"
      },
      {
        "label": "SAN FABIAN-CHILLAN",
        "value": "CL-08-22-21"
      },
      {
        "label": "SAN GREGORIO-CHILLAN",
        "value": "CL-08-22-28"
      },
      {
        "label": "SAN IGNACIO-CHILLAN",
        "value": "CL-08-22-3"
      },
      {
        "label": "SAN NICOLAS-CHILLAN",
        "value": "CL-08-22-22"
      },
      {
        "label": "SAN PEDRO DE LA PAZ-CONCEPCION",
        "value": "CL-08-8-7"
      },
      {
        "label": "SAN PEDRO-CONCEPCION",
        "value": "CL-08-8-49"
      },
      {
        "label": "SAN ROSENDO-LOS ANGELES",
        "value": "CL-08-54-19"
      },
      {
        "label": "SAN VICENTE-CONCEPCION",
        "value": "CL-08-8-5"
      },
      {
        "label": "SANTA BARBARA-CONCEPCION",
        "value": "CL-08-8-22"
      },
      {
        "label": "SANTA BARBARA-LOS ANGELES",
        "value": "CL-08-54-4"
      },
      {
        "label": "SANTA FE-LOS ANGELES",
        "value": "CL-08-54-20"
      },
      {
        "label": "SANTA JUANA-CONCEPCION",
        "value": "CL-08-8-15"
      },
      {
        "label": "TALCAHUANO-CONCEPCION",
        "value": "CL-08-8-6"
      },
      {
        "label": "TIRUA-CONCEPCION",
        "value": "CL-08-8-50"
      },
      {
        "label": "TOME-CONCEPCION",
        "value": "CL-08-8-13"
      },
      {
        "label": "TREHUACO-CHILLAN",
        "value": "CL-08-22-23"
      },
      {
        "label": "TRUPAN-CHILLAN",
        "value": "CL-08-22-24"
      },
      {
        "label": "TUCAPEL-CHILLAN",
        "value": "CL-08-22-25"
      },
      {
        "label": "ÝUBLE-CHILLAN",
        "value": "CL-08-22-6"
      },
      {
        "label": "YUMBEL-CONCEPCION",
        "value": "CL-08-8-51"
      },
      {
        "label": "YUNGAY-CHILLAN",
        "value": "CL-08-22-26"
      }
    ],
    "Coquimbo": [
      {
        "label": "ANDACOLLO-LA SERENA",
        "value": "CL-04-4-15"
      },
      {
        "label": "BARRAZA-LA SERENA",
        "value": "CL-04-4-16"
      },
      {
        "label": "CANELA-LA SERENA",
        "value": "CL-04-4-17"
      },
      {
        "label": "COMBARBALA-LA SERENA",
        "value": "CL-04-4-10"
      },
      {
        "label": "COQUIMBO-LA SERENA",
        "value": "CL-04-4-2"
      },
      {
        "label": "EL SOLDADO-LA SERENA",
        "value": "CL-04-4-18"
      },
      {
        "label": "ELQUI-LA SERENA",
        "value": "CL-04-4-19"
      },
      {
        "label": "GUANAQUERO-LA SERENA",
        "value": "CL-04-4-20"
      },
      {
        "label": "ILLAPEL-LA SERENA",
        "value": "CL-04-4-5"
      },
      {
        "label": "LA HIGUERA-LA SERENA",
        "value": "CL-04-4-21"
      },
      {
        "label": "LA SERENA-LA SERENA",
        "value": "CL-04-4-3"
      },
      {
        "label": "LAS COMPAÑIAS-LA SERENA",
        "value": "CL-04-4-11"
      },
      {
        "label": "LOS VILOS-LA SERENA",
        "value": "CL-04-4-22"
      },
      {
        "label": "MINCHA OTELCUMA-LA SERENA",
        "value": "CL-04-4-23"
      },
      {
        "label": "MONTE PATRIA-LA SERENA",
        "value": "CL-04-4-24"
      },
      {
        "label": "OVALLE-LA SERENA",
        "value": "CL-04-4-8"
      },
      {
        "label": "PAIHUANO-LA SERENA",
        "value": "CL-04-4-25"
      },
      {
        "label": "PUNITAQUI-LA SERENA",
        "value": "CL-04-4-7"
      },
      {
        "label": "RIO HURTADO-LA SERENA",
        "value": "CL-04-4-1"
      },
      {
        "label": "SALAMANCA-LA SERENA",
        "value": "CL-04-4-6"
      },
      {
        "label": "SAMO ALTO-LA SERENA",
        "value": "CL-04-4-26"
      },
      {
        "label": "SOTAQUI-LA SERENA",
        "value": "CL-04-4-9"
      },
      {
        "label": "TIERRAS BLANCAS-LA SERENA",
        "value": "CL-04-4-14"
      },
      {
        "label": "TONGOY-LA SERENA",
        "value": "CL-04-4-13"
      },
      {
        "label": "VICUÑA-LA SERENA",
        "value": "CL-04-4-12"
      }
    ],
    "Región de Los Lagos": [
      {
        "label": "ACHAO-PUERTO MONTT",
        "value": "CL-10-10-24"
      },
      {
        "label": "ALTO PALENA-PUERTO MONTT",
        "value": "CL-10-10-25"
      },
      {
        "label": "ANCUD-PUERTO MONTT",
        "value": "CL-10-10-14"
      },
      {
        "label": "CALBUCO-PUERTO MONTT",
        "value": "CL-10-10-21"
      },
      {
        "label": "CARELMAPU-PUERTO MONTT",
        "value": "CL-10-10-26"
      },
      {
        "label": "CASTRO-PUERTO MONTT",
        "value": "CL-10-10-2"
      },
      {
        "label": "CHACAO-PUERTO MONTT",
        "value": "CL-10-10-27"
      },
      {
        "label": "CHAITEN-PUERTO MONTT",
        "value": "CL-10-10-28"
      },
      {
        "label": "CHILOE-PUERTO MONTT",
        "value": "CL-10-10-29"
      },
      {
        "label": "CHONCHI-PUERTO MONTT",
        "value": "CL-10-10-23"
      },
      {
        "label": "COCHAMO-PUERTO MONTT",
        "value": "CL-10-10-30"
      },
      {
        "label": "CURACO DE VELEZ-PUERTO MONTT",
        "value": "CL-10-10-31"
      },
      {
        "label": "DALCAHUE-PUERTO MONTT",
        "value": "CL-10-10-18"
      },
      {
        "label": "ENTRE LAGOS-OSORNO",
        "value": "CL-10-55-6"
      },
      {
        "label": "FRESIA-PUERTO MONTT",
        "value": "CL-10-10-1"
      },
      {
        "label": "FRUTILLAR-PUERTO MONTT",
        "value": "CL-10-10-20"
      },
      {
        "label": "FUTALEUFU-PUERTO MONTT",
        "value": "CL-10-10-32"
      },
      {
        "label": "FUTRONO-OSORNO",
        "value": "CL-10-55-5"
      },
      {
        "label": "FUTRONO-PUERTO MONTT",
        "value": "CL-10-10-17"
      },
      {
        "label": "HAULAIHUE-PUERTO MONTT",
        "value": "CL-10-10-33"
      },
      {
        "label": "HORNOPIREN-PUERTO MONTT",
        "value": "CL-10-10-34"
      },
      {
        "label": "LA UNION-OSORNO",
        "value": "CL-10-55-2"
      },
      {
        "label": "LA UNION-PUERTO MONTT",
        "value": "CL-10-10-9"
      },
      {
        "label": "LAGO RANCO-OSORNO",
        "value": "CL-10-55-7"
      },
      {
        "label": "LLANQUIHUE-PUERTO MONTT",
        "value": "CL-10-10-5"
      },
      {
        "label": "LOS LAGOS-PUERTO MONTT",
        "value": "CL-10-10-12"
      },
      {
        "label": "LOS MUERMOS-PUERTO MONTT",
        "value": "CL-10-10-19"
      },
      {
        "label": "MAULLIN-PUERTO MONTT",
        "value": "CL-10-10-3"
      },
      {
        "label": "NUEVA BRAUNAU-PUERTO MONTT",
        "value": "CL-10-10-35"
      },
      {
        "label": "OCTAY-OSORNO",
        "value": "CL-10-55-8"
      },
      {
        "label": "OSORNO-OSORNO",
        "value": "CL-10-55-1"
      },
      {
        "label": "OSORNO-PUERTO MONTT",
        "value": "CL-10-10-8"
      },
      {
        "label": "PAILLACO-PUERTO MONTT",
        "value": "CL-10-10-11"
      },
      {
        "label": "PALENA-PUERTO MONTT",
        "value": "CL-10-10-36"
      },
      {
        "label": "PARGUA-PUERTO MONTT",
        "value": "CL-10-10-22"
      },
      {
        "label": "PILAUCO-OSORNO",
        "value": "CL-10-55-9"
      },
      {
        "label": "PUAUCHO-OSORNO",
        "value": "CL-10-55-10"
      },
      {
        "label": "PUERTO MONTT-PUERTO MONTT",
        "value": "CL-10-10-4"
      },
      {
        "label": "PUERTO OCTAY-OSORNO",
        "value": "CL-10-55-11"
      },
      {
        "label": "PUERTO VARAS-PUERTO MONTT",
        "value": "CL-10-10-10"
      },
      {
        "label": "PUQUELDON-PUERTO MONTT",
        "value": "CL-10-10-37"
      },
      {
        "label": "PURRANQUE-OSORNO",
        "value": "CL-10-55-3"
      },
      {
        "label": "PURRANQUE-PUERTO MONTT",
        "value": "CL-10-10-15"
      },
      {
        "label": "PUYEHUE-OSORNO",
        "value": "CL-10-55-12"
      },
      {
        "label": "QUEILEN-PUERTO MONTT",
        "value": "CL-10-10-38"
      },
      {
        "label": "QUELLON-PUERTO MONTT",
        "value": "CL-10-10-39"
      },
      {
        "label": "QUEMCHI-PUERTO MONTT",
        "value": "CL-10-10-40"
      },
      {
        "label": "QUINCHAO-PUERTO MONTT",
        "value": "CL-10-10-41"
      },
      {
        "label": "RAHUE-OSORNO",
        "value": "CL-10-55-13"
      },
      {
        "label": "RAPACO-OSORNO",
        "value": "CL-10-55-14"
      },
      {
        "label": "RIO BUENO-OSORNO",
        "value": "CL-10-55-4"
      },
      {
        "label": "RIO BUENO-PUERTO MONTT",
        "value": "CL-10-10-16"
      },
      {
        "label": "RIO FRIO-PUERTO MONTT",
        "value": "CL-10-10-42"
      },
      {
        "label": "RIO NEGRO - OSORNO-OSORNO",
        "value": "CL-10-55-15"
      },
      {
        "label": "RIO NEGRO - PUERTO MONTT-PUERTO MONTT",
        "value": "CL-10-10-43"
      },
      {
        "label": "RIO NEGRO-PUERTO MONTT",
        "value": "CL-10-10-6"
      },
      {
        "label": "RIO PUELO-PUERTO MONTT",
        "value": "CL-10-10-44"
      },
      {
        "label": "SAN JUAN DE LA COSTA-OSORNO",
        "value": "CL-10-55-16"
      },
      {
        "label": "SAN PABLO-OSORNO",
        "value": "CL-10-55-17"
      },
      {
        "label": "VALDIVIA-PUERTO MONTT",
        "value": "CL-10-10-13"
      }
    ],
    "Región del Libertador Gral. Bernardo": [
      {
        "label": "CHEPICA-RANCAGUA",
        "value": "CL-06-6-20"
      },
      {
        "label": "CHIMBARONGO-RANCAGUA",
        "value": "CL-06-6-6"
      },
      {
        "label": "CODEGUA-RANCAGUA",
        "value": "CL-06-6-21"
      },
      {
        "label": "COINCO-RANCAGUA",
        "value": "CL-06-6-22"
      },
      {
        "label": "COLTAUCO-RANCAGUA",
        "value": "CL-06-6-13"
      },
      {
        "label": "COYA-RANCAGUA",
        "value": "CL-06-6-23"
      },
      {
        "label": "DONIHUE-RANCAGUA",
        "value": "CL-06-6-24"
      },
      {
        "label": "EL MANZANO-RANCAGUA",
        "value": "CL-06-6-25"
      },
      {
        "label": "EL OLIVAR-RANCAGUA",
        "value": "CL-06-6-26"
      },
      {
        "label": "GRANEROS-RANCAGUA",
        "value": "CL-06-6-2"
      },
      {
        "label": "LA ESTRELLA-RANCAGUA",
        "value": "CL-06-6-27"
      },
      {
        "label": "LAS CABRAS-RANCAGUA",
        "value": "CL-06-6-28"
      },
      {
        "label": "LITUECHE-RANCAGUA",
        "value": "CL-06-6-29"
      },
      {
        "label": "LOLOL-RANCAGUA",
        "value": "CL-06-6-30"
      },
      {
        "label": "MACHALI-RANCAGUA",
        "value": "CL-06-6-10"
      },
      {
        "label": "MALLOA-RANCAGUA",
        "value": "CL-06-6-4"
      },
      {
        "label": "MARCHIGUE-RANCAGUA",
        "value": "CL-06-6-31"
      },
      {
        "label": "NANCAGUA-RANCAGUA",
        "value": "CL-06-6-9"
      },
      {
        "label": "NAVIDAD-RANCAGUA",
        "value": "CL-06-6-32"
      },
      {
        "label": "OLIVAR-RANCAGUA",
        "value": "CL-06-6-7"
      },
      {
        "label": "PALMILLA-RANCAGUA",
        "value": "CL-06-6-19"
      },
      {
        "label": "PAREDONES-RANCAGUA",
        "value": "CL-06-6-33"
      },
      {
        "label": "PELEQUEN-RANCAGUA",
        "value": "CL-06-6-12"
      },
      {
        "label": "PERALILLO-RANCAGUA",
        "value": "CL-06-6-34"
      },
      {
        "label": "PEUMO-RANCAGUA",
        "value": "CL-06-6-35"
      },
      {
        "label": "PICHIDANGUI-RANCAGUA",
        "value": "CL-06-6-17"
      },
      {
        "label": "PICHIDEGUA-RANCAGUA",
        "value": "CL-06-6-16"
      },
      {
        "label": "PICHILEMU-RANCAGUA",
        "value": "CL-06-6-36"
      },
      {
        "label": "PUMANQUE-RANCAGUA",
        "value": "CL-06-6-37"
      },
      {
        "label": "QUINTA DE TILCOCO-RANCAGUA",
        "value": "CL-06-6-38"
      },
      {
        "label": "RANCAGUA-RANCAGUA",
        "value": "CL-06-6-1"
      },
      {
        "label": "RAPEL-RANCAGUA",
        "value": "CL-06-6-39"
      },
      {
        "label": "RENGO-RANCAGUA",
        "value": "CL-06-6-5"
      },
      {
        "label": "REQUINOA-RANCAGUA",
        "value": "CL-06-6-14"
      },
      {
        "label": "SAN FERNANDO-RANCAGUA",
        "value": "CL-06-6-3"
      },
      {
        "label": "SAN FRANCISCO DE MOSTAZAL-RANCAGUA",
        "value": "CL-06-6-18"
      },
      {
        "label": "SAN VICENTE DE TAGUA TAGUA-RANCAGUA",
        "value": "CL-06-6-40"
      },
      {
        "label": "SAN VICENTE T.T-RANCAGUA",
        "value": "CL-06-6-8"
      },
      {
        "label": "SAN VICENTE-RANCAGUA",
        "value": "CL-06-6-11"
      },
      {
        "label": "SANTA CRUZ-RANCAGUA",
        "value": "CL-06-6-15"
      },
      {
        "label": "SEWELL-RANCAGUA",
        "value": "CL-06-6-41"
      }
    ],
    "Región de Magallanes y de la Antártica Chilena": [
      {
        "label": "SAN GREGORIO (PA)-PUNTA ARENAS",
        "value": "CL-12-12-9"
      },
      {
        "label": "CERRO SOMBRERO-PUNTA ARENAS",
        "value": "CL-12-12-3"
      },
      {
        "label": "NAVARINO-PUNTA ARENAS",
        "value": "CL-12-12-4"
      },
      {
        "label": "PRIMAVERA-PUNTA ARENAS",
        "value": "CL-12-12-5"
      },
      {
        "label": "PUERTO HARRIS-PUNTA ARENAS",
        "value": "CL-12-12-6"
      },
      {
        "label": "pUERTO NATALES-PUNTA ARENAS",
        "value": "CL-12-12-2"
      },
      {
        "label": "PUERTO PORVENIR-PUNTA ARENAS",
        "value": "CL-12-12-7"
      },
      {
        "label": "PUERTO WILLIAMS-PUNTA ARENAS",
        "value": "CL-12-12-8"
      },
      {
        "label": "PUNTA ARENAS-PUNTA ARENAS",
        "value": "CL-12-12-1"
      },
      {
        "label": "TIERRA DEL FUEGO-PUNTA ARENAS",
        "value": "CL-12-12-10"
      }
    ],
    "Región del Maule": [
      {
        "label": "CAUQUENES-TALCA",
        "value": "CL-07-7-14"
      },
      {
        "label": "CHANCO-TALCA",
        "value": "CL-07-7-16"
      },
      {
        "label": "COLBUN-TALCA",
        "value": "CL-07-7-17"
      },
      {
        "label": "CONSTITUCION-TALCA",
        "value": "CL-07-7-2"
      },
      {
        "label": "CUMPEO-TALCA",
        "value": "CL-07-7-18"
      },
      {
        "label": "CUNACO-TALCA",
        "value": "CL-07-7-19"
      },
      {
        "label": "CURANIPE-TALCA",
        "value": "CL-07-7-20"
      },
      {
        "label": "CUREPTO-TALCA",
        "value": "CL-07-7-10"
      },
      {
        "label": "CURICO-CURICO",
        "value": "CL-07-57-1"
      },
      {
        "label": "CURICO-TALCA",
        "value": "CL-07-7-8"
      },
      {
        "label": "EMPEDRADO-TALCA",
        "value": "CL-07-7-21"
      },
      {
        "label": "GUALLECO-TALCA",
        "value": "CL-07-7-22"
      },
      {
        "label": "HUALANE-TALCA",
        "value": "CL-07-7-23"
      },
      {
        "label": "ITAHUE-TALCA",
        "value": "CL-07-7-24"
      },
      {
        "label": "LICANTEN-TALCA",
        "value": "CL-07-7-25"
      },
      {
        "label": "LINARES-TALCA",
        "value": "CL-07-7-7"
      },
      {
        "label": "LONGAVI-TALCA",
        "value": "CL-07-7-26"
      },
      {
        "label": "LONTUE-TALCA",
        "value": "CL-07-7-27"
      },
      {
        "label": "MAULE-TALCA",
        "value": "CL-07-7-4"
      },
      {
        "label": "MOLINA-TALCA",
        "value": "CL-07-7-9"
      },
      {
        "label": "PELARCO-TALCA",
        "value": "CL-07-7-15"
      },
      {
        "label": "PELLUHUE-TALCA",
        "value": "CL-07-7-28"
      },
      {
        "label": "PENCAHUE-TALCA",
        "value": "CL-07-7-29"
      },
      {
        "label": "RAUCO-TALCA",
        "value": "CL-07-7-35"
      },
      {
        "label": "RETIRO-TALCA",
        "value": "CL-07-7-30"
      },
      {
        "label": "RIO CLARO - TALCA-TALCA",
        "value": "CL-07-7-31"
      },
      {
        "label": "ROMERAL-TALCA",
        "value": "CL-07-7-11"
      },
      {
        "label": "SAGRADA FAMILIA-TALCA",
        "value": "CL-07-7-12"
      },
      {
        "label": "SAN CLEMENTE-TALCA",
        "value": "CL-07-7-6"
      },
      {
        "label": "SAN JAVIER-TALCA",
        "value": "CL-07-7-5"
      },
      {
        "label": "SAN RAFAEL-TALCA",
        "value": "CL-07-7-3"
      },
      {
        "label": "TALCA-TALCA",
        "value": "CL-07-7-1"
      },
      {
        "label": "TENO-TALCA",
        "value": "CL-07-7-13"
      },
      {
        "label": "VICHUQUEN-TALCA",
        "value": "CL-07-7-32"
      },
      {
        "label": "VILLA ALEGRE-TALCA",
        "value": "CL-07-7-33"
      },
      {
        "label": "YERBAS BUENAS-TALCA",
        "value": "CL-07-7-34"
      }
    ],
    "Región Metropolitana de Santiago": [
      {
        "label": "Alhué-MELIPILLA",
        "value": "CL-13-63-1"
      },
      {
        "label": "ALTO JAHUEL-SANTIAGO",
        "value": "CL-13-13-76"
      },
      {
        "label": "BATUCO-SANTIAGO",
        "value": "CL-13-13-27"
      },
      {
        "label": "BUIN-SANTIAGO",
        "value": "CL-13-13-25"
      },
      {
        "label": "CALERA DE TANGO-SANTIAGO",
        "value": "CL-13-13-65"
      },
      {
        "label": "CERRILLOS-SANTIAGO",
        "value": "CL-13-13-38"
      },
      {
        "label": "CERRO NAVIA-SANTIAGO",
        "value": "CL-13-13-16"
      },
      {
        "label": "CHAMPA-SANTIAGO",
        "value": "CL-13-13-77"
      },
      {
        "label": "CODIGUA-SANTIAGO",
        "value": "CL-13-13-78"
      },
      {
        "label": "COLINA-SANTIAGO",
        "value": "CL-13-13-58"
      },
      {
        "label": "CONCHALI-SANTIAGO",
        "value": "CL-13-13-17"
      },
      {
        "label": "CURIMON-SANTIAGO",
        "value": "CL-13-13-79"
      },
      {
        "label": "EL ALMENDRAL-SANTIAGO",
        "value": "CL-13-13-80"
      },
      {
        "label": "EL BOSQUE-SANTIAGO",
        "value": "CL-13-13-32"
      },
      {
        "label": "EL MONTE-SANTIAGO",
        "value": "CL-13-13-50"
      },
      {
        "label": "EL PAICO-SANTIAGO",
        "value": "CL-13-13-81"
      },
      {
        "label": "EST. CENTRAL-SANTIAGO",
        "value": "CL-13-13-4"
      },
      {
        "label": "EST.CENTRAL-SANTIAGO",
        "value": "CL-13-13-39"
      },
      {
        "label": "HOSPITAL-SANTIAGO",
        "value": "CL-13-13-82"
      },
      {
        "label": "HUECHURABA-SANTIAGO",
        "value": "CL-13-13-19"
      },
      {
        "label": "INDEPENDENCIA-SANTIAGO",
        "value": "CL-13-13-14"
      },
      {
        "label": "ISLA DE MAIPO-SANTIAGO",
        "value": "CL-13-13-47"
      },
      {
        "label": "LA CISTERNA-SANTIAGO",
        "value": "CL-13-13-28"
      },
      {
        "label": "LA DEHESA-SANTIAGO",
        "value": "CL-13-13-74"
      },
      {
        "label": "LA FLORIDA-SANTIAGO",
        "value": "CL-13-13-9"
      },
      {
        "label": "LA GRANJA-SANTIAGO",
        "value": "CL-13-13-43"
      },
      {
        "label": "LA PINTANA-SANTIAGO",
        "value": "CL-13-13-18"
      },
      {
        "label": "LA REINA-SANTIAGO",
        "value": "CL-13-13-68"
      },
      {
        "label": "LAMPA-SANTIAGO",
        "value": "CL-13-13-63"
      },
      {
        "label": "LAS CONDES-SANTIAGO",
        "value": "CL-13-13-2"
      },
      {
        "label": "LAS TORTOLAS-SANTIAGO",
        "value": "CL-13-13-83"
      },
      {
        "label": "LINDEROS-SANTIAGO",
        "value": "CL-13-13-84"
      },
      {
        "label": "LO BARNECHEA-SANTIAGO",
        "value": "CL-13-13-73"
      },
      {
        "label": "LO ESPEJO-SANTIAGO",
        "value": "CL-13-13-36"
      },
      {
        "label": "LO PRADO-SANTIAGO",
        "value": "CL-13-13-22"
      },
      {
        "label": "LOS ANGELES-SANTIAGO",
        "value": "CL-13-13-01"
      },
      {
        "label": "MACUL-SANTIAGO",
        "value": "CL-13-13-11"
      },
      {
        "label": "MAIPU-SANTIAGO",
        "value": "CL-13-13-5"
      },
      {
        "label": "MALLOCO-SANTIAGO",
        "value": "CL-13-13-75"
      },
      {
        "label": "MARIA PINTO-SANTIAGO",
        "value": "CL-13-13-85"
      },
      {
        "label": "MELIPILLA-SANTIAGO",
        "value": "CL-13-13-37"
      },
      {
        "label": "NOVICIADO-SANTIAGO",
        "value": "CL-13-13-86"
      },
      {
        "label": "ÑUÑOA-SANTIAGO",
        "value": "CL-13-13-24"
      },
      {
        "label": "P. AGUIRRE C.-SANTIAGO",
        "value": "CL-13-13-34"
      },
      {
        "label": "PADRE HURTADO-SANTIAGO",
        "value": "CL-13-13-29"
      },
      {
        "label": "PAINE-SANTIAGO",
        "value": "CL-13-13-59"
      },
      {
        "label": "PEDRO AGUIRRE CERDA-SANTIAGO",
        "value": "CL-13-13-12"
      },
      {
        "label": "PELDEHUE-SANTIAGO",
        "value": "CL-13-13-87"
      },
      {
        "label": "PEÑAFLOR-SANTIAGO",
        "value": "CL-13-13-40"
      },
      {
        "label": "PEÑALOLEN-SANTIAGO",
        "value": "CL-13-13-15"
      },
      {
        "label": "PETORCA-SANTIAGO",
        "value": "CL-13-13-88"
      },
      {
        "label": "PIRQUE-SANTIAGO",
        "value": "CL-13-13-66"
      },
      {
        "label": "POLPAICO-SANTIAGO",
        "value": "CL-13-13-89"
      },
      {
        "label": "PROVIDENCIA-SANTIAGO",
        "value": "CL-13-13-13"
      },
      {
        "label": "PUDAHUEL-SANTIAGO",
        "value": "CL-13-13-10"
      },
      {
        "label": "PUENTE ALTO-SANTIAGO",
        "value": "CL-13-13-7"
      },
      {
        "label": "QTA. NORMAL-SANTIAGO",
        "value": "CL-13-13-41"
      },
      {
        "label": "QUILICURA-SANTIAGO",
        "value": "CL-13-13-3"
      },
      {
        "label": "QUINTA NORMAL-SANTIAGO",
        "value": "CL-13-13-21"
      },
      {
        "label": "RECOLETA-SANTIAGO",
        "value": "CL-13-13-23"
      },
      {
        "label": "RENCA-SANTIAGO",
        "value": "CL-13-13-6"
      },
      {
        "label": "SAN BERNARDO-SANTIAGO",
        "value": "CL-13-13-8"
      },
      {
        "label": "SAN ESTEBAN-SANTIAGO",
        "value": "CL-13-13-90"
      },
      {
        "label": "SAN JOAQUIN-SANTIAGO",
        "value": "CL-13-13-42"
      },
      {
        "label": "SAN JOSE DE MAIPO-SANTIAGO",
        "value": "CL-13-13-26"
      },
      {
        "label": "SAN MIGUEL-SANTIAGO",
        "value": "CL-13-13-20"
      },
      {
        "label": "SAN PEDRO DE MELIPILLA-SANTIAGO",
        "value": "CL-13-13-91"
      },
      {
        "label": "SAN RAMON-SANTIAGO",
        "value": "CL-13-13-46"
      },
      {
        "label": "SANTA BLANCA-SANTIAGO",
        "value": "CL-13-13-92"
      },
      {
        "label": "SANTIAGO-SANTIAGO",
        "value": "CL-13-13-1"
      },
      {
        "label": "TALAGANTE-SANTIAGO",
        "value": "CL-13-13-31"
      },
      {
        "label": "TIL-TIL-SANTIAGO",
        "value": "CL-13-13-72"
      },
      {
        "label": "TRIANA-SANTIAGO",
        "value": "CL-13-13-94"
      },
      {
        "label": "VILLANELLO-SANTIAGO",
        "value": "CL-13-13-95"
      },
      {
        "label": "VITACURA-SANTIAGO",
        "value": "CL-13-13-67"
      }
    ],
    "Tarapacá": [
      {
        "label": "ALTO HOSPICIO-IQUIQUE",
        "value": "CL-01-1-2"
      },
      {
        "label": "ARICA-IQUIQUE",
        "value": "CL-01-1-3"
      },
      {
        "label": "CAMINA-IQUIQUE",
        "value": "CL-01-1-4"
      },
      {
        "label": "COLCHANE-IQUIQUE",
        "value": "CL-01-1-5"
      },
      {
        "label": "HUARA-IQUIQUE",
        "value": "CL-01-1-6"
      },
      {
        "label": "IQUIQUE-IQUIQUE",
        "value": "CL-01-1-1"
      },
      {
        "label": "MAMINA-IQUIQUE",
        "value": "CL-01-1-7"
      },
      {
        "label": "PICA-IQUIQUE",
        "value": "CL-01-1-8"
      },
      {
        "label": "PISAGUA-IQUIQUE",
        "value": "CL-01-1-9"
      },
      {
        "label": "POZO ALMONTE-IQUIQUE",
        "value": "CL-01-1-10"
      },
      {
        "label": "TARAPACA-IQUIQUE",
        "value": "CL-01-1-11"
      },
      {
        "label": "ZAPIGA-IQUIQUE",
        "value": "CL-01-1-12"
      }
    ],
    "Valparaíso": [
      {
        "label": "CHAGRES ( VALPARAISO )-VALPARAISO",
        "value": "CL-05-5-52"
      },
      {
        "label": "ALGARROBO-VALPARAISO",
        "value": "CL-05-5-10"
      },
      {
        "label": "BELLOTO-VALPARAISO",
        "value": "CL-05-5-19"
      },
      {
        "label": "CABILDO-VALPARAISO",
        "value": "CL-05-5-29"
      },
      {
        "label": "CACHAGUA-VALPARAISO",
        "value": "CL-05-5-30"
      },
      {
        "label": "CALLE LARGA-VALPARAISO",
        "value": "CL-05-5-56"
      },
      {
        "label": "CARTAGENA-VALPARAISO",
        "value": "CL-05-5-14"
      },
      {
        "label": "CASABLANCA-VALPARAISO",
        "value": "CL-05-5-18"
      },
      {
        "label": "CATEMU-VALPARAISO",
        "value": "CL-05-5-27"
      },
      {
        "label": "CHINCOLCO-VALPARAISO",
        "value": "CL-05-5-31"
      },
      {
        "label": "CHORRILLOS-VALPARAISO",
        "value": "CL-05-5-20"
      },
      {
        "label": "CONCON-VALPARAISO",
        "value": "CL-05-5-32"
      },
      {
        "label": "CON-CON-VALPARAISO",
        "value": "CL-05-5-13"
      },
      {
        "label": "CUNCUMEN-VALPARAISO",
        "value": "CL-05-5-33"
      },
      {
        "label": "CURACAVI-VALPARAISO",
        "value": "CL-05-5-28"
      },
      {
        "label": "EL BELLOTO-VALPARAISO",
        "value": "CL-05-5-34"
      },
      {
        "label": "EL MELON-VALPARAISO",
        "value": "CL-05-5-35"
      },
      {
        "label": "EL QUISCO-VALPARAISO",
        "value": "CL-05-5-23"
      },
      {
        "label": "EL TABO-VALPARAISO",
        "value": "CL-05-5-22"
      },
      {
        "label": "HIJUELAS-VALPARAISO",
        "value": "CL-05-5-36"
      },
      {
        "label": "HORCON-VALPARAISO",
        "value": "CL-05-5-37"
      },
      {
        "label": "ISLA DE PASCUA-VALPARAISO",
        "value": "CL-05-5-38"
      },
      {
        "label": "LA CALERA-VALPARAISO",
        "value": "CL-05-5-8"
      },
      {
        "label": "LA CRUZ-VALPARAISO",
        "value": "CL-05-5-39"
      },
      {
        "label": "LA LAGUNA-VALPARAISO",
        "value": "CL-05-5-40"
      },
      {
        "label": "LA LIGUA-VALPARAISO",
        "value": "CL-05-5-16"
      },
      {
        "label": "LAS CRUCES-VALPARAISO",
        "value": "CL-05-5-55"
      },
      {
        "label": "LIMACHE-VALPARAISO",
        "value": "CL-05-5-12"
      },
      {
        "label": "LLAY-LLAY-VALPARAISO",
        "value": "CL-05-5-9"
      },
      {
        "label": "LLO-LLEO-VALPARAISO",
        "value": "CL-05-5-17"
      },
      {
        "label": "LOS ANDES-VALPARAISO",
        "value": "CL-05-5-24"
      },
      {
        "label": "MAITENCILLO-VALPARAISO",
        "value": "CL-05-5-41"
      },
      {
        "label": "NOGALES-VALPARAISO",
        "value": "CL-05-5-42"
      },
      {
        "label": "OLMUE-VALPARAISO",
        "value": "CL-05-5-15"
      },
      {
        "label": "PANQUEHUE-VALPARAISO",
        "value": "CL-05-5-43"
      },
      {
        "label": "PAPUDO-VALPARAISO",
        "value": "CL-05-5-44"
      },
      {
        "label": "PENABLANCA-VALPARAISO",
        "value": "CL-05-5-45"
      },
      {
        "label": "PENUELAS-VALPARAISO",
        "value": "CL-05-5-46"
      },
      {
        "label": "PUCHUNCAVI-VALPARAISO",
        "value": "CL-05-5-47"
      },
      {
        "label": "PUERTO DE SAN ANTONIO-VALPARAISO",
        "value": "CL-05-5-48"
      },
      {
        "label": "PUTAENDO-VALPARAISO",
        "value": "CL-05-5-26"
      },
      {
        "label": "QUILLOTA-VALPARAISO",
        "value": "CL-05-5-11"
      },
      {
        "label": "QUILPUE-VALPARAISO",
        "value": "CL-05-5-2"
      },
      {
        "label": "QUINTERO-VALPARAISO",
        "value": "CL-05-5-25"
      },
      {
        "label": "REÑACA-VALPARAISO",
        "value": "CL-05-5-4"
      },
      {
        "label": "RIO BLANCO-VALPARAISO",
        "value": "CL-05-5-57"
      },
      {
        "label": "ROCAS DE SANTO DOMINGO-VALPARAISO",
        "value": "CL-05-5-49"
      },
      {
        "label": "SALADILLO-VALPARAISO",
        "value": "CL-05-5-53"
      },
      {
        "label": "SAN ANTONIO-VALPARAISO",
        "value": "CL-05-5-6"
      },
      {
        "label": "SAN FELIPE-VALPARAISO",
        "value": "CL-05-5-3"
      },
      {
        "label": "SAN SEBASTIAN-VALPARAISO",
        "value": "CL-05-5-54"
      },
      {
        "label": "SANTO DOMINGO-VALPARAISO",
        "value": "CL-05-5-21"
      },
      {
        "label": "VALPARAISO-VALPARAISO",
        "value": "CL-05-5-1"
      },
      {
        "label": "VENTANAS-VALPARAISO",
        "value": "CL-05-5-50"
      },
      {
        "label": "VILLA ALEMANA-VALPARAISO",
        "value": "CL-05-5-7"
      },
      {
        "label": "VIÑA DEL MAR-VALPARAISO",
        "value": "CL-05-5-5"
      },
      {
        "label": "ZAPALLAR-VALPARAISO",
        "value": "CL-05-5-51"
      }
    ],
    "Parinacota": [
      {
        "label": "ARICA-ARICA",
        "value": "CL-15-51-1"
      },
      {
        "label": "CUYA-ARICA",
        "value": "CL-15-51-5"
      },
      {
        "label": "HANSHINH-ARICA",
        "value": "CL-15-51-2"
      },
      {
        "label": "PARINACOTA-ARICA",
        "value": "CL-15-51-3"
      },
      {
        "label": "PUTRE-ARICA",
        "value": "CL-15-51-4"
      }
    ],
    "Región de Los Ríos": [
      {
        "label": "MALLOA ( VALDIVIA )-VALDIVIA",
        "value": "CL-14-21-10"
      },
      {
        "label": "CHOSHUENCO-VALDIVIA",
        "value": "CL-14-21-6"
      },
      {
        "label": "CORRAL-VALDIVIA",
        "value": "CL-14-21-7"
      },
      {
        "label": "LANCO-VALDIVIA",
        "value": "CL-14-21-3"
      },
      {
        "label": "LONCOCHE-VALDIVIA",
        "value": "CL-14-21-2"
      },
      {
        "label": "LOS LAGOS-VALDIVIA",
        "value": "CL-14-21-4"
      },
      {
        "label": "MAFIL-VALDIVIA",
        "value": "CL-14-21-8"
      },
      {
        "label": "MALAHUE-VALDIVIA",
        "value": "CL-14-21-9"
      },
      {
        "label": "MEHUIN-VALDIVIA",
        "value": "CL-14-21-11"
      },
      {
        "label": "PAILLACO-VALDIVIA",
        "value": "CL-14-21-1"
      },
      {
        "label": "PANGUIPULLI-VALDIVIA",
        "value": "CL-14-21-12"
      },
      {
        "label": "SAN JOSE DE LA MARIQUINA-VALDIVIA",
        "value": "CL-14-21-13"
      },
      {
        "label": "VALDIVIA-VALDIVIA",
        "value": "CL-14-21-5"
      }
    ]
  }
};


export const AFP = [
  {
    value: "PROVIDA",

    label: "PROVIDA",
  },

  {
    value: "HABITAT",

    label: "HABITAT",
  },

  {
    value: "EXTRANJEROS",

    label: "EXTRANJEROS",
  },

  {
    value: "CUPRUM",

    label: "CUPRUM",
  },

  {
    value: "CAPITAL",

    label: "CAPITAL",
  },

  {
    value: "JUBILADOS",

    label: "JUBILADOS",
  },

  {
    value: "HABITAT 2",

    label: "HABITAT 2",
  },

  {
    value: "MODELO",

    label: "MODELO",
  },

  {
    value: "EMPART",

    label: "EMPART",
  },

  {
    value: "PLANVITAL",

    label: "PLANVITAL",
  },
  {
    value: "S.S.S.",

    label: "S.S.S.",
  },
  {
    value: "PROVIDA 2",

    label: "PROVIDA 2",
  },
  {
    value: "CANAEMPU",

    label: "CANAEMPU",
  },
  {
    value: "CAPITAL 2",

    label: "CAPITAL 2",
  },
  {
    value: "Instituto de Prevision Social",

    label: "Instituto de Prevision Social",
  },
  {
    value: "UNO",

    label: "UNO",
  },
  {
    value: "NULA",

    label: "NULA",
  },
]

export const ISAPRE = [
  {
    value: "CONSALUD",

    label: "CONSALUD",
  },

  {
    value: "FONASA",

    label: "FONASA",
  },

  {
    value: "CHUQUICAMATA",

    label: "CHUQUICAMATA",
  },

  {
    value: "CRUZBLANCA",

    label: "CRUZBLANCA",
  },

  {
    value: "FUSAT",

    label: "FUSAT",
  },

  {
    value: "COLMENA GOLDEN CROSS",

    label: "COLMENA GOLDEN CROSS",
  },

  {
    value: "BANMEDICA",

    label: "BANMEDICA",
  },

  {
    value: "Isapre San Lorenzo",

    label: "Isapre San Lorenzo",
  },

  {
    value: "VIDA TRES",

    label: "VIDA TRES",
  },

  {
    value: "NUEVA MAS VIDA",

    label: "NUEVA MAS VIDA",
  },
  {
    value: "FUNDACION",

    label: "FUNDACION",
  },
  {
    value: "FERROSALUD",

    label: "FERROSALUD",
  },
  {
    value: "NULO",

    label: "NULO",
  },
]

export const Bancos = [
  {
    value: "Sin banco",

    label: "Sin banco",
  },

  {
    value: "BANCO DE CHILE",

    label: "BANCO DE CHILE",
  },

  {
    value: "BANCO BBVA",

    label: "BANCO BBVA",
  },

  {
    value: "BANCO ABN AMRO",

    label: "BANCO ABN AMRO",
  },

  {
    value: "BANCO CORPBANCA",

    label: "BANCO CORPBANCA",
  },

  {
    value: "BANCO SANTIAGO",

    label: "BANCO SANTIAGO",
  },

  {
    value: "BANCO DEL DESARROLLO",

    label: "BANCO DEL DESARROLLO",
  },

  {
    value: "BANCO BCI",

    label: "BANCO BCI",
  },

  {
    value: "BANCOESTADO",

    label: "BANCOESTADO",
  },

  {
    value: "BANCO DE A. EDWARDS",

    label: "BANCO DE A. EDWARDS",
  },
  {
    value: "BANCO ITAU",

    label: "BANCO ITAU",
  },
  {
    value: "CITIBANK",

    label: "CITIBANK",
  },
  {
    value: "BANCO SUDAMERICANO",

    label: "BANCO SUDAMERICANO",
  },
  {
    value: "BANCO SANTANDER",

    label: "BANCO SANTANDER",
  },
  {
    value: "BANEFE",

    label: "BANEFE",
  },
  {
    value: "BANCO FALABELLA",

    label: "BANCO FALABELLA",
  },
  {
    value: "BANCO SECURITY PACIFIC",

    label: "BANCO SECURITY PACIFIC",
  },
  {
    value: "BANCO SCOTIABANK",

    label: "BANCO SCOTIABANK",
  },
  {
    value: "BANCO BICE",

    label: "BANCO BICE",
  },
  {
    value: "Cheque Ret.Judicial",

    label: "Cheque Ret.Judicial",
  },
  {
    value: "BANCO CONSORCIO",

    label: "BANCO CONSORCIO",
  },
]
